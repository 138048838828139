import Dexie from 'dexie';
import axios from "axios";

export const db = new Dexie('SurveyDB');
db.version(1).stores({
    submissions: '++id,email',
});

db.version(3).stores({
    emails: '++id,email',
});
//


db.version(2).stores({
    questions: '++id,question_text,question_type,options',
});

db.version(39).stores({
    maxParticipant: '++id,max_participant',
});


db.open();
export const addSubmission = (submission) => {
    console.log(submission)
    return db.submissions.add(submission);
};

export const addMaxPart = (maxPart) => {
    console.log("maxPart", maxPart)
    return db.maxParticipant.add(maxPart);
};

export const syncSubmissions = async () => {
    const submissions = await db.submissions.toArray();

    console.log(submissions)

    if (submissions.length === 0) return;

    try {
        const body = {
            submissions: submissions
        }

        console.log(submissions)

        await axios.post('https://admin-panel.masscom-ci.com/api/bulk-submit', body)

        await db.submissions.clear()
        await db.submissions.bulkDelete(submissions.map((s) => s.id));

    } catch (error) {

        // toast.error("Max participant reached")
    }
};

export const fetchQuestions = async () => {
    try {
        const response = await fetch('https://admin-panel.masscom-ci.com/api/questions');
        if (response.ok) {
            const questions = await response.json();
            await db.questions.clear()

            if (!questions?.data?.questions) {
                await db.questions.clear()
            } else {
                await db.questions.put(questions?.data?.questions);
            }
        }
    } catch (error) {

        console.log('Error:', error);
    }
};

export const getStoredQuestions = async () => {
    try {
        return await db.questions.toArray()
    } catch (error) {
        console.log('Error:', error);
        return [];
    }
};
