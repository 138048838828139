import * as React from 'react';
import {useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '30rem',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

export default function VideoModal({open, onOpen, onClose, videoId}) {
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        // Check if the video element and play method are available
        if (video && video.play) {
            // Attempt to play the video
            const playPromise = video.play();

            // Handle autoplay restrictions (e.g., user hasn't interacted with the page)
            if (playPromise !== undefined) {
                playPromise
                    .then(() => {
                        // Autoplay was successful
                        console.log('Autoplay was successful');
                    })
                    .catch((error) => {
                        // Autoplay was prevented, handle it here
                        console.error('Autoplay was prevented:', error);
                    });
            }
        }
    }, []);
    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style} >
                    <div >
                        <div className={"text-right mb-3"}>
                            <button onClick={onClose}><CloseIcon/></button>
                        </div>
                        <video
                            ref={videoRef}
                            src={videoId}
                            autoPlay
                            controls
                        >
                        </video>
                    </div>

                </Box>
            </Modal>
        </div>
    );
}