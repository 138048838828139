import React, { useEffect } from 'react';

const VideoModal = ({ videoUrl, onClose }) => {
    // Add an event listener for clicks outside of the modal
    useEffect(() => {
        const handleClickOutside = (event) => {
            const modal = document.querySelector('.modal-container');
            if (modal && !modal.contains(event.target)) {
                onClose(); // Close the modal if the click is outside of it
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70">
            <div className="modal-container bg-white p-4 w-full md:w-3/4 lg:w-1/2 h-auto max-h-screen rounded-lg relative">
                <button
                    className="absolute top-2 right-2 text-xl text-gray-600 hover:text-gray-900"
                    onClick={onClose}
                >
                    &#10005;
                </button>
                <div className="aspect-w-16 aspect-h-9 mx-auto h-[30rem]">
                    <iframe
                        title="Video"
                        width="100%" // Match the width of the aspect ratio container
                        height="100%" // Match the height of the aspect ratio container
                        src={videoUrl}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default VideoModal;
