import React, {createContext, useEffect, useState} from 'react';
import {addMaxPart, db} from "./db";
import {useOnline} from "./video.page";

export const MaxParticipantContext = createContext(null);

export const MaxParticipantProvider = ({children}) => {
    const [maxParticipant, setMaxParticipant] = useState(null);
    const isOnline = useOnline();


    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                if (isOnline) {
                    const response = await fetch('https://admin-panel.masscom-ci.com/api/questions');
                    if (response.ok) {
                        const questions = await response.json();
                        await db.maxParticipant.clear()
                        addMaxPart({"maxPart": questions?.data?.questions?.max_part});

                    }
                }
            } catch (error) {
                console.log('Error:', error);
            }
        };

        fetchQuestions()
    }, [isOnline])
    return (
        <MaxParticipantContext.Provider value={{maxParticipant, setMaxParticipant}}>
            {children}
        </MaxParticipantContext.Provider>
    );
};