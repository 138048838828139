import Logo from "../assets/images/logo.png";
import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useOnline} from "../video.page";
import {fetchQuestions, getStoredQuestions} from "../db";
import {SurveyContext} from "../surveyContext";
import VideoModal from "../video-model";

const VideoClips = () => {
    const isOnline = useOnline();
    const {surveyCompleted} = useContext(SurveyContext);
    const [videos, setVideos] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState('');

    const openModal = (videoUrl) => {
        setSelectedVideoUrl(videoUrl);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedVideoUrl('');
        setModalOpen(false);
    };
    useEffect(() => {
        async function fetchCachedVideo() {
            const cacheName = 'my-video-cache'; // Replace with your cache name from the service worker
            const videoUrl1 = '/videos-cache/video_clips/Didi B, Franglish - S’envolement (clip officiel).mp4'; // Replace with the actual video file path
            const videoUrl2 = '/videos-cache/video_clips/FIOR 2 BIOR, DIDI B - ON SE MET BIEN (CLIP OFFICIEL).mp4'; // Replace with the actual video file path
            const videoUrl3 = '/videos-cache/video_clips/Harley - T\'malade (Clip officiel) ft. Didi B.mp4'; // Replace with the actual video file path
            const videoUrl4 = "/videos-cache/video_clips/JOSEY - JE TE KALA PAS ( Clip Officiel ).mp4"; // Replace with the actual video file path
            const videoUrl5 = '/videos-cache/video_clips/KEROZEN   (LES PROS CLIP OFFICIAL).mp4'; // Replace with the actual video file path

            const videoUrl6 = '/videos-cache/video_clips/Lil Jay Bingerack - BAOULE SUPER STAR (Clip Officiel).mp4'; // Replace with the actual video file path
            const videoUrl7 = '/videos-cache/video_clips/Revolution - On Est Puissant (Clip officiel).mp4'; // Replace with the actual video file path
            const videoUrl8 = '/videos-cache/video_clips/Roseline Layo - Amour Kôyô Kôyô (Vidéo Officielle).mp4'; // Replace with the actual video file path
            const videoUrl9 = "/videos-cache/video_clips/Serge Beynaud - Kiniwara - Clip officiel.mp4"; // Replace with the actual video file path
            const videoUrl10 = '/videos-cache/video_clips/Serge Beynaud x Ste Milano - Wé dans Wé - Clip Officiel.mp4'; // Replace with the actual video file path

            const videoUrl11 = '/videos-cache/video_clips/TIESCO LE SULTAN - Ya DIEU dedans ( CLIP OFFICIEL ).mp4'; // Replace with the actual video file path
            const videoUrl12 = '/videos-cache/video_clips/VDA - SHÉKÉTÉ - CLIP OFFICIEL.mp4'; // Replace with the actual video file path
            const videoUrl13 = "/videos-cache/video_clips/VDA - ZIAMOLO Clip Officiel.mp4"; // Replace with the actual video file path
            const videoUrl14 = '/videos-cache/video_clips/ZEYNAB - ODEKA (Clip officiel).mp4'; // Replace with the actual video file path


            const videos = [
                {
                    url: videoUrl1,
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-1.png',
                    title: "Didi B, Franglish - S’envolement (clip officiel)"
                },
                {
                    url: videoUrl2,
                    title: "FIOR 2 BIOR, DIDI B - ON SE MET BIEN (CLIP OFFICIEL)",
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-2.png',
                },
                {
                    url: videoUrl3,
                    title: "Harley - T\'malade (Clip officiel) ft. Didi B",
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-3.png',
                },
                {
                    url: videoUrl4,
                    title: "JOSEY - JE TE KALA PAS ( Clip Officiel )",
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-4.png',
                },
                {
                    url: videoUrl5,
                    title: "KEROZEN   (LES PROS CLIP OFFICIAL)",
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-5.png',
                },{
                    url: videoUrl6,
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-6.png',
                    title: "Lil Jay Bingerack - BAOULE SUPER STAR (Clip Officiel)"
                },
                {
                    url: videoUrl7,
                    title: "Revolution - On Est Puissant (Clip officiel)",
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-7.png',
                },
                {
                    url: videoUrl8,
                    title: "Roseline Layo - Amour Kôyô Kôyô (Vidéo Officielle)",
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-8.png',
                },
                {
                    url: videoUrl9,
                    title: "Serge Beynaud - Kiniwara - Clip officiel",
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-9.png',
                },
                {
                    url: videoUrl10,
                    title: "Serge Beynaud x Ste Milano - Wé dans Wé - Clip Officiel",
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-10.png',
                },
                {
                    url: videoUrl11,
                    title: "TIESCO LE SULTAN - Ya DIEU dedans ( CLIP OFFICIEL )",
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-11.png',
                },
                {
                    url: videoUrl12,
                    title: "VDA - SHÉKÉTÉ - CLIP OFFICIEL",
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-12.png',
                },
                {
                    url: videoUrl13,
                    title: "VDA - ZIAMOLO Clip Officiel",
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-13.png',
                },
                {
                    url: videoUrl14,
                    title: "ZEYNAB - ODEKA (Clip officiel)",
                    thumbnail: '/videos-cache/video_clips/thumbnail-video-14.png',
                },

            ]
            setVideos(videos)
        }

        fetchCachedVideo();
    }, []);

    useEffect(() => {
        const fetchSurveyQuestions = async () => {
            if (isOnline) {
                await fetchQuestions();
            }

            const storedQuestions = await getStoredQuestions();


            setQuestions(storedQuestions);
        };

        fetchSurveyQuestions();
    }, [isOnline]);
    return (
        <>
            {surveyCompleted ?
                <>
                    <div className={"mb-10"}>
                        <nav style={{textAlign: "center"}}>
                            <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
                        </nav>
                        <div className={"my-10"}>
                            <Link to={'/video'}>
                                <button className={"text-3xl bg-indigo-400 text-white px-2 py-1 rounded-lg"}>
                                    {"<< Retour à l’Accueil"}
                                </button>
                            </Link>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {videos.map((video, index) => (
                                <div key={index} className="bg-white p-4 rounded shadow-md">
                                    <button onClick={() => openModal(video.url)}>
                                        <img src={video.thumbnail} alt={video.title} className="w-full h-auto" />
                                    </button>
                                    <p className="mt-2 text-center font-semibold text-2xl">{video.title}</p>
                                </div>
                            ))}
                            {modalOpen && (
                                <VideoModal videoUrl={selectedVideoUrl} onClose={closeModal} />
                            )}
                        </div>
                    </div>
                </> : <div className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}>
                    <nav style={{textAlign: "center"}}>
                        <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
                    </nav>
                    <div className={"px-5 py-4 text-center bg-amber-50 rounded-2xl"}>
                        <p>
                            POUR ACCÉDER AUX VIDEOS/MUSIQUE MERCI DE PRENDRE 5 SECONDES POUR REPONDRE A UNE QUESTION 
                        </p>

                        <div>
                            <Link to={'/'}>
                                <button className={"bg-indigo-400 rounded-2xl px-3 py-2 my-4 text-white"}>
                                    Accéder aux videos
                                </button>
                            </Link>
                        </div>

                    </div>
                </div>}
        </>

    );
}

export default VideoClips