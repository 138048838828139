import React, {useContext, useEffect, useState} from 'react';
import Logo from "./assets/images/logo.png";
import {Link} from "react-router-dom";
import {SurveyContext} from "./surveyContext";
import AudioModal from "./audio-model";
import AudioThumbnail from './assets/images/audio-thumbnail.jpg'

const MusicFolder = () => {
    const [currentTrack, setCurrentTrack] = useState(null);
    const {surveyCompleted} = useContext(SurveyContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAudioUrl, setSelectedAudioUrl] = useState('');
    const [tracks, setTracks] = useState([]);

    const openModal = (audioUrl) => {
        setSelectedAudioUrl(audioUrl);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedAudioUrl('');
        setModalOpen(false);
    };
    useEffect(() => {
        async function fetchCachedVideo() {
            const cacheName = 'my-video-cache'; // Replace with your cache name from the service worker
            const videoUrl1 = '/audio-cache/MIX AFRO 2021.mp3'; // Replace with the actual video file path
            const videoUrl2 = '/audio-cache/MIX CONGO.mp3'; // Replace with the actual video file path
            const videoUrl3 = '/audio-cache/MIX COUPER DECALER 2021.mp3'; // Replace with the actual video file path
            const videoUrl4 = "/audio-cache/MIX Dj ARAFAT.mp3"; // Replace with the actual video file path
            const videoUrl5 = '/audio-cache/MIX HIP HOP (USA).mp3'; // Replace with the actual video file path

            const videoUrl6 = '/audio-cache/MIX RUMBA.mp3'; // Replace with the actual video file path
            const videoUrl7 = '/audio-cache/MIX ZOUGLOU.mp3'; // Replace with the actual video file path
           

            const videos = [
                {
                    url: videoUrl1,
                    thumbnail: '/audio-cache/thumbnail-video-1.png',
                    title: "MIX AFRO 2021.mp3"
                },
                {
                    url: videoUrl2,
                    title: "MIX CONGO.mp3",
                    thumbnail: '/audio-cache/thumbnail-video-2.png',
                },
                {
                    url: videoUrl3,
                    title: "MIX COUPER DECALER 2021.mp3",
                    thumbnail: '/audio-cache/thumbnail-video-3.png',
                },
                {
                    url: videoUrl4,
                    title: "MIX Dj ARAFAT.mp3",
                    thumbnail: '/audio-cache/thumbnail-video-4.png',
                },
                {
                    url: videoUrl5,
                    title: "MIX HIP HOP (USA).mp3",
                    thumbnail: '/audio-cache/thumbnail-video-5.png',
                },{
                    url: videoUrl6,
                    thumbnail: '/audio-cache/thumbnail-video-6.png',
                    title: "MIX RUMBA.mp3"
                },
                {
                    url: videoUrl7,
                    title: "MIX ZOUGLOU.mp3",
                    thumbnail: '/audio-cache/thumbnail-video-7.png',
                }

            ]
            setTracks(videos)
        }

        fetchCachedVideo();
    }, []);


    const playTrack = (track) => {
        if (currentTrack === track) {
            setCurrentTrack(null); // Pause if same track is clicked again
        } else {
            setCurrentTrack(track);
        }
    };
    return (
        <>
            {surveyCompleted ?
                <>
                    <nav style={{textAlign: "center"}}>
                        <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
                    </nav>
                    <div className={"my-10"}>
                        <Link to={'/video'}>
                            <button className={"text-3xl bg-indigo-400 text-white px-2 py-1 rounded-lg"}>
                                {"<< Retour à l’Accueil"}
                            </button>
                        </Link>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {tracks.map((track, index) => (
                            <div key={index} className="bg-white p-4 rounded shadow-md">
                                <button onClick={() => openModal(track.url)}>
                                    <img src={AudioThumbnail} alt={track.title} className="w-full h-auto"/>
                                </button>
                                <p className="mt-2 text-center font-semibold text-2xl">{track.title}</p>
                            </div>
                        ))}
                        {modalOpen && (
                            <AudioModal audioUrl={selectedAudioUrl} onClose={closeModal}/>
                        )}
                    </div>
                </>
                : <div className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}>
                    <nav style={{textAlign: "center"}}>
                        <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
                    </nav>
                    <div className={"px-5 py-4 text-center bg-amber-50 rounded-2xl"}>
                        <p>
                            POUR ACCÉDER AUX VIDEOS/MUSIQUE MERCI DE PRENDRE 5 SECONDES POUR REPONDRE A UNE QUESTION 
                        </p>

                        <div>
                            <Link to={'/'}>
                                <button className={"bg-indigo-400 rounded-2xl px-3 py-2 my-4 text-white"}>
                                    VIDEOS/PLAYLISTS
                                </button>
                            </Link>
                        </div>

                    </div>
                </div>}
        </>

    );
};

export default MusicFolder;