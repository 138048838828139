import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Logo from "./assets/images/logo.png";
import {SurveyContext} from "./surveyContext";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {db, fetchQuestions, getStoredQuestions, syncSubmissions} from "./db";


const useOnline = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };


    }, []);

    return isOnline;
};


const SurveyEntry = () => {
    const navigate = useNavigate();
    const {setSurveyCompleted} = useContext(SurveyContext);
    const {control, register, handleSubmit, formState: {errors}} = useForm();

    const [savedEmails, setSavedEmails] = useState([]);

    const [questions, setQuestions] = useState([]);

    const isOnline = useOnline();

    const [maxParticipant, setMaxParticipant] = useState(0)


    useEffect(() => {
        if (isOnline) {
            syncSubmissions();
        }


    }, [isOnline]);


    useEffect(() => {
        // Call the function to fetch saved emails when the component mounts
        if (isOnline) {
            fetchEmailsFromApi()
        }


        console.log(maxParticipant)
        if (!isOnline) {
            fetchSavedEmails()
        }

    }, [isOnline, maxParticipant, setMaxParticipant]);

    const fetchSavedEmails = async () => {
        // Function to fetch saved emails from the database and update state
        const emails = await db.emails.toArray();
        setSavedEmails(emails);
    };

    useEffect(() => {
        const fetchMaxPart = async () => {
            const maxPart = await db.maxParticipant.toArray();
            console.log("maxPartpkk", maxPart[0]?.maxPart)
            setMaxParticipant(maxPart[0]?.maxPart)
        };

        fetchMaxPart()

        if (maxParticipant <= 0 ){
            setSurveyCompleted(true)
        }
    }, [maxParticipant])


    const fetchEmailsFromApi = async () => {
        try {
            if (isOnline) {
                const response = await axios.get('https://admin-panel.masscom-ci.com/api/answers');
                const emailsFromApi = response.data?.data?.emails;
                console.log(response.data)
                await db.emails.clear()
                await saveEmailsToDB(emailsFromApi);
                await fetchSavedEmails();
            }

        } catch (error) {
            console.error('Error fetching emails from API:', error);
        }
    };

    const saveEmailsToDB = async (emails) => {
        for (const email of emails) {
            await db.emails.add({email});
        }
    };

    useEffect(() => {
        const fetchSurveyQuestions = async () => {
            if (isOnline) {
                await fetchQuestions();
            }

            const storedQuestions = await getStoredQuestions();


            setQuestions(storedQuestions);
        };

        fetchSurveyQuestions();
    }, [isOnline, navigate]);




    const onSubmit = async (e) => {
        try {
            const email = e?.email
            const body = {email: email};
            const emails = await db.emails.toArray();

            if (!isOnline) {
                const isAlready = emails?.find((v) => v?.email === email)

                console.log("emails", emails)
                console.log("isAlready", isAlready)

                db.emails.add({email});

                if (isAlready) {
                    // toast.error("Accédez aux vidéos /Playlists")
                    setSurveyCompleted(true)
                    navigate({
                        pathname: '/video'
                    });
                    return;
                }
                // toast.error('Formulaire soumis hors ligne. Il sera synchronisé lorsque le réseau sera disponible.');
                navigate({
                    pathname: '/survey', search: `?email=${email}`,
                });
                return;
            }

            const response = await axios.post('https://admin-panel.masscom-ci.com/api/check_email', body)

            if (response?.data?.status) {
                setSurveyCompleted(true)
                navigate({
                    pathname: '/survey', search: `?email=${email}`,
                });
            } else {
                // toast.success("Accédez aux vidéos /Playlists")
                setSurveyCompleted(true)
                navigate({
                    pathname: '/video'
                });
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
            setSurveyCompleted(true)
            navigate({
                pathname: '/video'
            });
        }
    }

    return (<>

        {maxParticipant <= 0 ? <div>
                <nav style={{textAlign: "center"}}>
                    <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
                </nav>

                <div className={"text-center"}>
                    <p>There is no active survey please wait for survey activation.</p>
                    <Link to={'/video'}>
                        <button className={"py-1 bg-indigo-400 px-2 mt-3 rounded-xl"}>
                            Accédez aux vidéos /Playlists
                        </button>
                    </Link>
                </div>
            </div> :
            <div style={{
                width: '100vw',
                height: '100vh',
                backgroundPosition: "center",
                backgroundImage: `url(${questions[0]?.company_bg})`
            }}>
                <nav style={{textAlign: "center"}}>
                    <img src={Logo} style={{width: '200px', height: "200px", margin: "auto"}} alt={"logo"}/>
                </nav>

                <div className="quiz-container">
                    <p>Entrez votre Email ou numéro et Accédez à nos films / Vidéos / Liste de musique </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={"flex flex-col my-4"}>
                            <label htmlFor="email" className={"font-bold"}>Email ou numéro et Accédez</label>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                    validate: (value) => {
                                        // Define a regex pattern for phone numbers and email addresses
                                        const regex = /^(?:\d{10}|[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})$/;
                                        return regex.test(value) || "Merci d’entrer un numéro ou e-mail valide";
                                    },
                                }}
                                render={({field}) => (
                                    <>
                                        <input {...register("email", {required: true})} type="text"
                                               id="email" name="email"
                                               placeholder=""/>
                                        {errors.email && (
                                            <p className="error-message text-red-400">
                                                {errors.email.message}
                                            </p>
                                        )}
                                    </>
                                )}
                            />
                        </div>

                        <button type="submit" ><span className={"text-2xl"}>Accédez aux videos</span></button>
                    </form>
                </div>

            </div>
        }
    </>)
}

export default SurveyEntry