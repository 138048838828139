import React from 'react';
import {Button, Card, CardBody, CardText, CardTitle, Modal} from 'reactstrap';
import useIosInstallPrompt from './hooks/useIosPrompt';
import useWebInstallPrompt from './hooks/useOtherPrompt';
import Logo from './assets/images/logo.png'

export const InstallPWA = () => {
    const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
    const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

    console.log("webInstallPrompt", webInstallPrompt)
    if (!iosInstallPrompt && !webInstallPrompt) {
        return null;
    }
    return (
        <Modal isOpen centered>
            <Card>
                <img
                    className="mx-auto"
                    style={{
                        borderTopRightRadius: '50%',
                        borderTopLeftRadius: '50%',
                        backgroundColor: '#fff',
                        marginTop: '-50px'
                    }}
                    src={Logo}
                    alt="Icon"
                    width="100px"
                />
                <CardBody>
                    <CardTitle className="text-center">
                        <h3>Install App</h3>
                    </CardTitle>
                    {iosInstallPrompt && (
                        <>
                            <CardText className="text-center text-black">
                                Tap
                                <img

                                    src={Logo}
                                    style={{margin: 'auto 8px 8px'}}
                                    className="text-black"
                                    width="20px"
                                    alt="Add to homescreen"
                                />
                                then &quot;Add to Home Screen&quot;
                            </CardText>
                            <div className="d-flex justify-content-center text-black">
                                <Button onClick={handleIOSInstallDeclined}>Close</Button>
                            </div>
                        </>
                    )}
                    {webInstallPrompt && (
                        <div className="d-flex justify-content-around">
                            <button className={"btn btn-primary"} onClick={handleWebInstallAccepted}>
                                Install
                            </button>
                            <button className={"btn btn-danger"} onClick={handleWebInstallDeclined}>Close</button>
                        </div>
                    )}
                </CardBody>
            </Card>
        </Modal>
    );
};